import Label from "./../../components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { useParams } from "react-router";
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useEventDashboardLoader from "hooks/useEventDashboardLoader";
import Heading from "components/Heading/Heading";
import WidgetHeading1 from "containers/BlogPage/WidgetHeading1";
import CardCategory1 from "components/CardCategory1/CardCategory1";
import { DEMO_CATEGORIES } from "data/taxonomies";
import { TaxonomyType } from "data/types";
import { AlbumOutlined, CollectionsOutlined, PhotoAlbumRounded } from "@mui/icons-material";
import { onSnapshot, getDocs, doc, collection, query, where, orderBy, DocumentData, getDoc, updateDoc } from "firebase/firestore";
import { auth, fs } from "firebaseSetup";
import { EventPriceDataType } from "data/types";
import FormItem from "containers/PageCreateEvent/FormItem";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface AccountPageProps {
  className?: string;
}

const EventSettings: FC<AccountPageProps> = ({ className = "" }) => {

    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [eventData, setEventData] = useState<DocumentData>({
      title: '',
      description: '',
      descriptions: {
        en: [],
        zh: [],
      },
      photoURL: '',
      photoURLs: [],
    })
    const [eventPrices, setEventPrices] = useState<{[key: string]: EventPriceDataType}>({})
    const [eventPriceItems, setEventPriceItems] = useState<string[]>([])

    async function uploadImage(file:File) {
      fetch('https://r2-worker.sam-12e.workers.dev/', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': file.type
        },
        body: file
      })
        .then((response) => response.text())
        .then((data) => {
          const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data
          
        }) // 
        .catch((error) => console.error(error));
    }

    async function loadEventRelatedData(){
      setIsLoading(true)
      let eventId = id || ""
      if (eventId != ""){
        //get
        getDoc(doc(fs,"events",eventId)).then((snapshot)=>{
          if (snapshot.exists()){
            let eventData = snapshot.data()
            setEventData({...eventData})
            if (eventData.prices != null){
              setEventPrices(eventData.prices)
              setEventPriceItems(Object.keys(eventData.prices))
            }
            setIsLoading(false)
          }
        })
      }
    }

    const [actionInProgress, setActionInProgress] = useState(false)
    async function duplicateEvent(){
      let userToken = localStorage.getItem('user_token') || ""
      let eventId = id || ""
      if (userToken != null && userToken != "" && eventId != ""){
        fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPIDuplicateEvent?event_id=${eventId}`,{
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + userToken
          }, 
        }).then((response) => {
          const responseTask = response.json()
          responseTask.then((data) => {
            let resultD = {success: false, status: '', error: ''}
            resultD = data
            if (resultD.success){
              //created
              setActionInProgress(false)
              window.location.replace('/account-organization')
            }
            else{
              //failture
            }
          })
        })
      }
      else{
        auth.currentUser?.getIdToken(true).then((userToken) => {
          localStorage.setItem('user_token',userToken)
          window.location.reload()
        })
      }
    }

    const MutipleImageGallery = ({ mainImage = '', topImage = '', bottomImage = '', showMoreIcon=false }) => {
      return (
        <div className="relative grid grid-cols-4 gap-1 sm:gap-2 w-full rounded-md sm:rounded-xl h-full">
          <div className="col-span-3 rounded-md sm:rounded-xl overflow-hidden bg-white-200">
            <img className="object-cover rounded-md sm:rounded-xl w-full h-full" src={mainImage} alt="Main" />
          </div>
          <div className="col-span-1 grid grid-rows-5 gap-1 overflow-hidden">
            <div className="row-span-3 rounded-md sm:rounded-xl bg-white-200 overflow-hidden">
              <img className="object-cover rounded-md sm:rounded-xl w-full h-full" src={topImage} alt="Top" />
            </div>
            <div className="relative row-span-2 rounded-md sm:rounded-xl bg-white-200 overflow-hidden">
              <img className="object-cover rounded-md sm:rounded-xl w-full h-full" src={bottomImage} alt="Bottom" />
              {showMoreIcon?<div className="absolute bottom-2 right-2 text-white text-xl">
                <CollectionsOutlined className="w-10 h-10 lg:w-14 lg:h-14"></CollectionsOutlined>
              </div>:<div></div>}
              
            </div>
          </div>
        </div>
      );
    };

    useEffect(()=>{
      //load all users
      loadEventRelatedData()
    },[])

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle={isLoading ? 'Loading...' : eventData.title}>
      <div className="space-y-6 sm:space-y-8">
      <div className="mt-1 text-neutral-500 dark:text-neutral-400 font-bold">Basic Information</div>
        <div className="mt-3">
            <FormItem desc="Change event name then update" label="Event Name *">
              <Input 
                onChange={(e)=>{
                  const newTitle = e.target.value
                  if (newTitle != ""){
                    setEventData({...eventData, title: newTitle})
                  }
                }}
                type={'text'} 
                defaultValue={eventData.title}/>
            </FormItem>
            <ButtonSecondary 
              loading={isLoading} 
              disabled={isLoading} 
              className="mt-3" onClick={()=>{
                //update name
                let eveId = id || ""
                if (eventData.title != null && eventData.title != "" && eveId != ""){
                  setIsLoading(true)
                  updateDoc(doc(fs,"events",eveId),{
                    title: eventData.title
                  }).then((aa)=>{
                    window.alert('Update successful')
                    window.location.reload()
                  })
                }
              }}>Update Event Name</ButtonSecondary>
        </div>

        <div className="mt-3">
            <FormItem desc="Update your event description" label="Event Description *">
              <Textarea 
                onChange={(e)=>{
                  const newDesc = e.target.value
                  if (newDesc != ""){
                    setEventData({...eventData, description: newDesc})
                  }
                }}
                rows={10} 
                defaultValue={eventData.description}/>
            </FormItem>
            <ButtonSecondary 
              loading={isLoading} 
              disabled={isLoading} 
              className="mt-3"
              onClick={()=>{
                if (eventData.description != null && eventData.description != ""){
                  let newDesc = ""
                  let newDescs: string[] = []
                  newDesc = eventData.description
                  if (newDesc.includes('\n')){
                    const descSet = newDesc.split('\n')
                    newDescs = descSet
                  }
                  else{
                    newDescs = [newDesc]
                  }
                  const eveId = id || ""
                  if (eveId != "" && newDesc != ""){
                    setIsLoading(true)
                    updateDoc(doc(fs,"events",eveId),{
                      description: eventData.description,
                      descriptions: {
                        en: newDescs,
                        zh: newDescs,
                      }
                    }).then((aa)=>{
                      window.alert('Update successful')
                      window.location.reload()
                    })
                  }
                  
                }
              }}>Update Event Description</ButtonSecondary>
        </div>

        <div className="mt-3">
            <FormItem desc="Update your event banner" label="Event Banner *">
           
            </FormItem>
        </div>
        <div className="mt-3">
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {
              eventData.photoURLs.map((imageURL:string) => {
                return <div>
                  <img className="object-cover rounded-md sm:rounded-xl w-[450px] h-[300px]" src={imageURL} alt="Main" />
                  <div className="absolute bottom-2 right-2 text-white text-xl">
            <CollectionsOutlined className="w-10 h-10 lg:w-14 lg:h-14"></CollectionsOutlined>
          </div>
          </div>
              })
            }
          </div>
        </div>

        <div className="mt-3 text-red-500 dark:text-red-400 font-bold">Danger Zone</div>
        <div className="mt-3">
            <FormItem label="Duplicate Event *">
              <div>
                <ButtonPrimary className="mt-3" loading={actionInProgress} disabled={actionInProgress} onClick={()=>{
                  let evtConfirm = window.confirm("Do you want to duplicate current event?")
                  if (evtConfirm){
                    setActionInProgress(true)
                    duplicateEvent()
                  }
                }}>Duplicate Event</ButtonPrimary>
              </div>
            </FormItem>
        </div>

      </div>
      </CommonLayout>
    </div>
  );
};

export default EventSettings;
