import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import EventCard from "components/EventCard/EventCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { EventDataType } from "data/types";
import { Paper, Typography,  Skeleton, Stack, Card, CardHeader, CardContent, CardMedia, CardActions, IconButton, Button } from '@mui/material'

export interface OrganizerPageProps {
  className?: string;
}

const OrganizerPage: FC<OrganizerPageProps> = ({ className = "" }) => {
  const [categories,setCategories] = useState(["Past Events", "Upcoming Events"]);
  const [infoLoaded, setInfoLoaded] = useState(false)
  const { id } = useParams()
  const [orgData, setOrgData] = useState({
      name: '',
      description: '',
      profileURL: '',
      location: '',
  })
  const [orgEvents, setOrgEvents] = useState<EventDataType[]>([])
  const [orgPastEvents, setOrgPastEvents] = useState<EventDataType[]>([])
  const [orgUpcomingEvents, setOrgUpcomingEvents] = useState<EventDataType[]>([])

  useEffect(()=>{
      if (!infoLoaded){

        fetch("https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPIFetchOrganizerInfoAndEvents?org_id=" + `${id || ""}`,{
            'headers': {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
          const responseTask = response.json();
          responseTask.then((data) => {
              if (data.status == "success"){
                  if (data.orgData != null){
                      setOrgData({
                          name: data.orgData.name,
                          description: data.orgData.description,
                          profileURL: data.orgData.profileURL,
                          location: data.orgData.location,
                      })
                  }
                  if (data.events != null){
                      let eventDataTypeData: EventDataType[] = []
                      let eventDataTypeUpcoming: EventDataType[] = []
                      let eventDataTypePast: EventDataType[] = []
                      const eventData = data.events.data
                      eventData.forEach((remoteItm: any) => {
                        let eId = ""
                        let eTitle = ""
                        let eDescription = ""
                        let ePhotoURL = ""
                        let ePhotoURLs: string[] = []
                        let eAddress = ""
                        let eStartDateString = ""
                        let eEndDateString = ""
                        let ePrice = 0
                       
                        if (remoteItm.id != null){
                            eId = remoteItm.id
                        }
                        if (remoteItm.title != null){
                            eTitle = remoteItm.title
                        }
                        if (remoteItm.description != null){
                            eDescription = remoteItm.description
                        }
                        if (remoteItm.startDateString != null){
                            eStartDateString = remoteItm.startDateString
                        }
                        if (remoteItm.endDateString != null){
                            eEndDateString = remoteItm.endDateString
                        }
                        if (remoteItm.photoURL != null){
                            ePhotoURL = remoteItm.photoURL
                        }
                        if (remoteItm.photoURLs != null){
                            ePhotoURLs = remoteItm.photoURLs
                            if (ePhotoURLs.length == 0){
                              ePhotoURLs = [ePhotoURL]
                            }
                        }
                        else{
                            ePhotoURLs = [ePhotoURL]
                        }
                        if (remoteItm.price != null){
                            ePrice = remoteItm.price
                        }
                        if (remoteItm.address != null){
                            if (remoteItm.address.fullAddress != null){
                                eAddress = remoteItm.address.fullAddress
                            }
                        }
                        let eventDataItem: EventDataType = {
                          id: eId,
                          title: eTitle,
                          description: eDescription,
                          startDateString: eStartDateString,
                          endDateString: eEndDateString,
                          photoURLs: ePhotoURLs,
                          photoURL: ePhotoURL,
                          viewCount: remoteItm.viewCount || 0,
                          location: remoteItm.location,
                          address: eAddress,
                          price: ePrice,
                          tags: [],
                          type: 'event',
                          priceCurrency: 'CAD',
                          priceDiscount: remoteItm.priceDiscount
                        }
                        eventDataTypeData.push(eventDataItem)

                        if (remoteItm.endDate != null){
                            let endSeconds = 0
                            if (remoteItm.endDate._seconds != null){
                                endSeconds = remoteItm.endDate._seconds
                            }
                            let endD = new Date(0)
                            endD.setUTCSeconds(endSeconds)
                            let today = new Date()
                            if (endD > today){
                                eventDataTypeUpcoming.push(eventDataItem)
                            }
                            else{
                                eventDataTypePast.push(eventDataItem)
                            }
                        }
                    })
                    setOrgPastEvents(eventDataTypePast)
                    setOrgUpcomingEvents(eventDataTypeUpcoming)
                    setOrgEvents(eventDataTypeData)
                    setInfoLoaded(true)
                  }
              }
              else {
                setInfoLoaded(false)
                window.location.assign('/')
              }
          })
        }).catch((e) => {
          window.location.assign('/')
        })

      }
  },[])

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          imgUrl={orgData.profileURL}
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-28 h-28"
        />

        {/* ---- */}
        <div className="space-y-3 text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{orgData.name}</h2>
          {/* <StartRating className="!text-base" /> */}
        </div>

        {/* ---- */}
        <p className="text-neutral-500 dark:text-neutral-400">
          {orgData.description}
        </p>

        {/* ---- */}
        {/* <SocialsList
          className="!space-x-3"
          itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
        /> */}

        {/* ---- */}
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        {/* ---- */}
        <div className="space-y-4">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {orgData.location == '' ? 'Toronto, Ontario' : orgData.location}
            </span>
          </div>
          {/* <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Speaking English
            </span>
          </div> */}

          {/* <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              Joined in March 2016
            </span>
          </div> */}
        </div>
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">View {orgData.name}'s Events</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {orgData.name} has {orgEvents.length} events
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {orgPastEvents.filter((_, i) => i < 4).map(
                    (eventItm) => (
                        <EventCard key={eventItm.id} data={eventItm} />
                    )
                  )}
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {orgUpcomingEvents.filter((_, i) => i < 4).map((eventItm) => (
                    <EventCard key={eventItm.id} data={eventItm} />
                  ))}
                </div>
              </Tab.Panel>
              
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_CAR_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <CarCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing hasListingTitle className="pb-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  function LoadingHeaderView(){
    return (
      <Card sx={{ width: '100%', marginTop: '15px', marginBottom: '15px', height: '90vh'}}>
        <CardHeader
          avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
          title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }}/>}
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
        <Skeleton sx={{ height: '50vh' }} animation="wave" variant="rectangular" />
        <CardContent>
          <React.Fragment>
              <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
        </CardContent>
      </Card>
    )
  }

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>{orgData.name} | EventGo</title>
        <meta property="og:title" content={`${orgData.name} | EventGo`} />
        <meta property="og:description" content={`${orgData.description.substring(0,120)} | EventGo`} />
        <meta property="og:url" content={"https://evtgo.com/organizer/" + id || ''}/>
        <meta property="og:site_name" content={`${orgData.name} | EventGo`} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={orgData.profileURL} />
      </Helmet>
      {!infoLoaded ? <LoadingHeaderView/> : 
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
      <div className="block flex-grow mb-24 lg:mb-0">
        <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
      </div>
      <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
        {renderSection1()}
      </div>
    </main>
      }
      
    </div>
  );
};

export default OrganizerPage;
