import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import EventCard from "components/EventCard/EventCard";
import React, { FC, Fragment, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { EventDataType } from "data/types";
import { Paper, Typography,  Skeleton, Stack, Card, CardHeader, CardContent, CardMedia, CardActions, IconButton, Button } from '@mui/material'
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface OrganizerPageProps {
  className?: string;
}

const PageSubscribeEmail: FC<OrganizerPageProps> = ({ className = "" }) => {
  const [infoLoaded, setInfoLoaded] = useState(true)
  const { email } = useParams()

  const [mode, setMode] = useState<string>('unsubscribe')

  async function unsubscribeFromList(){
    if (email != null && email != "" && mode == 'unsubscribe'){
      fetch("https://v1systemapiunsubscribefromcampaign-650318705789.us-central1.run.app?mode=unsubscribe&email=" + `${email || ""}`,{
          'headers': {
              'Content-Type': 'application/json'
          }
      }).then((response)=>{
          setInfoLoaded(true)
          const responseTask = response.json();
          responseTask.then((data) => {
              if (data.success){
                  alert("You have successfully unsubscribe")
              }
          })
      })
    }
  }

  useEffect(()=>{
      
  },[])

  function LoadingHeaderView(){
    return (
      <Card sx={{ width: '100%', marginTop: '15px', marginBottom: '15px', height: '90vh'}}>
        <CardHeader
          avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
          title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }}/>}
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
        <Skeleton sx={{ height: '50vh' }} animation="wave" variant="rectangular" />
        <CardContent>
          <React.Fragment>
              <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
        </CardContent>
      </Card>
    )
  }

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Subscribe to Newsletter</title>
      </Helmet>
      {!infoLoaded ? <LoadingHeaderView/> : 
      <div className="container mb-24 lg:mb-32">
      <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Unsubscribe {email || ""}
      </h2>
      <div className="text-small">Do you want to unsubscribe from email campaign?</div>
      <ButtonPrimary className="mt-2" onClick={()=>{
        unsubscribeFromList()
      }}>Unsubscribe</ButtonPrimary>
      </div>
      }
      
    </div>
  );
};

export default PageSubscribeEmail;
