import Label from "./../../components/Label/Label";
import React, { FC, useState, Fragment, useEffect } from "react";
import { useParams } from "react-router";
import { useSearchParams} from "react-router-dom"
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { DataGrid } from "@mui/x-data-grid";
import { Tab } from "@headlessui/react";
import { auth, fs } from "firebaseSetup";
import { Box, Paper, Button, IconButton, Typography, useTheme, Drawer, CircularProgress } from "@mui/material";
import { CSVLink } from 'react-csv'
import { CSVHeaderType, EventOrderType, EventPriceDataType, EventPrivateInviteDataType, EventPromoType, EventTicketType, OrderRequestType, EventSeatType } from "data/types";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Dialog } from '@headlessui/react';
import ManualInviteDialog from "./Invite/ManualInviteDialogPanel";
import CSVInviteDialog from "./Invite/CSVInviteDialogPanel";
import { Email, RefreshOutlined } from "@mui/icons-material";
import { getDocs, query, collection, orderBy, DocumentData, onSnapshot, Timestamp} from 'firebase/firestore'
import { isMobile } from "react-device-detect";
import FormItem from "containers/PageCreateEvent/FormItem";
import SearchDropdown from "components/Header/SearchDropdown";
import ButtonDanger from "shared/Button/ButtonDanger";

export interface AccountPageProps {
  className?: string;
}

const EventOrders: FC<AccountPageProps> = ({ className = "" }) => {

  const [isInvitePanelVisible, setIsInvitePanelVisible] = useState(false);
  const [isManualInviteDialogOpen, setIsManualInviteDialogOpen] = useState(false);
  const [isInviteTableRefreshing, setIsInviteTableRefreshing] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [inviteData, setInviteData] = useState<EventPrivateInviteDataType[]>([])
  const [isSeatDetailOpen, setIsSeatDetailOpen] = useState(false);
  const [inviteTypeData, setInviteTypeData] = useState({
    acceptNum: 0,
    pendingNum: 0,
    rejectNum: 0
  })

  const [selectedSeat, setSelectedSeat] = useState<EventSeatType>();

  const handleRefreshInviteTable = async () => {
    if (isInviteTableRefreshing) return; // 防止重复触发
    refreshInviteTable()
  };

  const refreshInviteTable = ()=>{
    let userUID = ""
    userUID = auth.currentUser?.uid || ""
    let userToken = localStorage.getItem('user_token') || ""
    let eventId = id || ""
    if (userToken != null && userToken !== "" && eventId !== ""){
      setIsInviteTableRefreshing(true);
      fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPIFetchAllInvitations`,{
    headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + userToken
      }, 
      method:'POST', 
      body:JSON.stringify({'event_id':eventId})}).then((response) => {
          const responseTask = response.json()
          responseTask.then((data) => {
            console.log(data)
            processInvitationInfo(data)
            setIsInviteTableRefreshing(false);
          })
      }).catch((err)=>{
        alert('invite data load failed, please try agian.')
        setIsInviteTableRefreshing(false);
      })
    }
  }

  const processInvitationInfo = (data:any)=>{
    if(data.success){
      const invitations = data.invitations
      const invitationData = invitations.data
      setInviteData(invitationData.map((row:any)=>({...row, id:row.inviteCode})))
    }
  }

  const sendInvite = (invitations:any)=>{
    // "event_id": "bJhpeV4LWtTFkpxS88cx",
    // "price_id": "default-price",
    // "invitation": [
    //     {
      //     "first_name": "Sam",
      //     "email": "sam@letjoy.app",
    //     "last_name": "Chen",
    //     "quantity": 1
    //     }
    // ]
    let userUID = ""
    userUID = auth.currentUser?.uid || ""
    let userToken = localStorage.getItem('user_token') || ""
    let eventId = id || ""
    if (userToken != null && userToken !== "" && eventId !== ""){
      setIsInviting(true)            
      fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPIEventPrivateInvite`,{
    headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + userToken
      }, 
      method:'POST', 
      body:JSON.stringify(
        {
          'event_id':eventId, 
          'price_id':'default-price',
          'invitation':invitations})}).then((response) => {
          const responseTask = response.json()
          responseTask.then((data) => {
            console.log(data)
            if(isInvitePanelVisible){
              closeCSVInviteDialog(); // Close dialog after submission
            }else{
              closeManualInviteDialog(); // Close dialog after submission
            }
            setIsInviting(false)
            refreshInviteTable()
          })
      }).catch((err)=>{
        alert('invite data load failed, please try agian.')
        setIsInviting(false)
        if(isInvitePanelVisible){
          closeCSVInviteDialog(); // Close dialog after submission
        }else{
          closeManualInviteDialog(); // Close dialog after submission
        }
      })
    }
  }  

  const openManualInviteDialog = () => {
    setIsManualInviteDialogOpen(true);
  };

  const closeManualInviteDialog = () => {
    setIsManualInviteDialogOpen(false);
  };

  const handleManualInviteSubmit = (formData: { email: string; firstName: string; lastName: string; phone: string }) => {
    console.log('Form submitted with data:', formData);
    sendInvite([{'email':formData.email, 'first_name':formData.firstName, 'last_name':formData.lastName, 'phone':formData.phone, 'quantity':1}])
  };

  const openCSVInviteDialog = ()=>{
    setIsInvitePanelVisible(true)
  }

  const closeCSVInviteDialog = ()=>{
    setIsInvitePanelVisible(false)
  }

  const handleCSVInviteSubmit = (inviteData:any) => {
    if(inviteData.length > 0){
      sendInvite(inviteData.map((row:any)=>({...row, 'quantity':1})))
    }else{
      alert('please check the csv file format')
    }
  }

  const handleTabChangEvent = (selectedIdx:number) =>{
    console.log("change categories=>"+categories[selectedIdx])
    if(selectedIdx === 2){
      handleRefreshInviteTable()
    }
  }


  const {id} = useParams()
  const [searchParams] = useSearchParams();
  const viewType = searchParams.get('view');

  let [eventPrices, setEventPrices] = useState<EventPriceDataType[]>([])
  

    const [eventItem, setEventItem] = useState({
        title: '',
        description: '',
        id: '',
    })

    let [categories, setCategories] = useState([
      "Orders",
      "Tickets",
      "Coupons",
      "Invitations",
      "Attend Requests",
      "Refund Requests",
      "Seats"]);

      const [currentTime,setCurrentTime] = useState('')


    const [allOrders, setAllOrders] = useState<EventOrderType[]>([])
    const [currentOrder, setCurrentOrder] = useState<EventOrderType>({
      id: '',
      amount: 0,
      eventId: '',
      title: '',
      description: '',
      photoURL: '',
      orgId:'',
      paymentId: '',
      uniqueId:'',
      userEmail:'',
      userUID:'',
      date:'',
      type:'',
      name:'',
      couponCode: '',
      status: '',
      statusMessage: ''
    })

    
    const [eventDashboard, setEventDashboard] = useState({
      dashboardItems: [
          {
              title: '',
              total: 0,
              time: '',
              notice: '',
          }
      ],
      eventItem: {
          title: 'Loading...',
          id: '',
          description: 'Loading...',
          pin: '',
          price: 0,
          prices: {}
      },
      currentTime: 'Loading...',
      mode: 'orders',
      tickets: [],
      members: [],
      orders: [],
      invites: [],
  })

  const [orderPromos, setOrderPromos] = useState<EventPromoType[]>([])

  const [invitedData, setInvitedData] = useState<DocumentData[]>([])
  const [requestData, setRequestData] = useState<DocumentData[]>([])
  const [orderSeats, setOrderSeats] = useState<EventSeatType[]>([])
  const [seatCategories, setSeatCategories] = useState({
    empty: 0,
    taken: 0,
    locked: 0
  })

  function loadAllOrderSeats(eventId: string){
    const seatRef = query(collection(fs, "events",eventId, 'seats'), orderBy('name','asc'))
    onSnapshot(seatRef, (snapshot)=>{
      let sseats: any[] = []
      snapshot.docs.forEach((sItm)=>{
        sseats.push(sItm.data())
      })
      setOrderSeats(sseats)
      
      //filter on seat types
      let emptyS = 0
      let takenS = 0
      let lockedS = 0
      sseats.forEach((seat)=>{
        if (seat.status != null){
          if (seat.status == 'pending'){
            emptyS += 1
          }
          else if (seat.status == 'taken'){
            takenS += 1
          }
          else {
            lockedS += 1
          }
        }
      })
      setSeatCategories({
        empty: emptyS,
        taken: takenS,
        locked: lockedS
      })
      
    })
  }

  async function changeSeatOperation(operation: String, eventId:String, seatId:String){
    let url = `https://v1organizerapiseatoperation-650318705789.us-central1.run.app?operation=${operation}&event_id=${eventId}&seat_id=${seatId}`
    const userToken = await auth.currentUser?.getIdToken(true)
    fetch(url,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + userToken
    }, }).then((resp) => {
      setIsSeatDetailOpen(false)
      window.alert("Success!")
    })
  }

  function loadInvitation(eventId: string){
    const inviteRef = query(collection(fs, "events",eventId, 'invites'), orderBy('timestamp','desc'));
    getDocs(inviteRef).then((snapshot)=>{
      let members: DocumentData[] = []
          snapshot.docs.forEach((doc) => {
            let docData = doc.data()
            docData.id = doc.id
            if (docData.timestamp != null){
              docData.timestamp = docData.timestamp.toDate().toLocaleString('en')
            }
            members.push(docData)
          })
          setInvitedData(members)
    })
  }

  function loadRequests(eventId: string){
    const inviteRef = query(collection(fs, "events",eventId, 'requests'), orderBy('timestamp','desc'));
    onSnapshot(inviteRef,(snapshot) => {
      let members: DocumentData[] = []
          snapshot.docs.forEach((doc) => {
            let docData = doc.data()
            docData.id = doc.id
            if (docData.timestamp != null){
              docData.timestamp = docData.timestamp.toDate().toLocaleString('en')
            }
            members.push(docData)
          })
          setRequestData(members)
    })
    
        
       
  }

  async function ticketTransferAction(ticketId:string, orderId: string, ticketTransferEmail: string, ticketTransferName: string){
    //check
    if (ticketTransferEmail != "" && 
        ticketTransferName != "" && 
        ticketTransferEmail.includes("@") && 
        ticketTransferEmail.includes(".") && 
        ticketId != "" && 
        orderId != ""){
          //validated
        const idToken = await auth.currentUser?.getIdToken(true)
        if (idToken != ""){
        fetch("https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPITransferTicket", {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ idToken
          },
          body: JSON.stringify({
            orderId: orderId,
            tickets: [ticketId],
            email: ticketTransferEmail,
            name: ticketTransferName
          }),
        })
        .then(response => response.json())
        .then((data) => {
          if (data.success) {
            setTransferInProgress(false)
            window.location.reload()
          }
          else{
            window.alert("Transfer Failed: Please try again")
          }
        }).catch((e) => {
          window.alert("Transfer Failed: " + e)
        })
      }
    }
    else{
      window.alert("Information is not correct!")
    }
  }
    

    useEffect(() => {
      console.log('triggered');
      const viewT = viewType || "Orders"

      setCurrentTime("")

      refreshInviteTable()
      loadRequests(id || "")

          let userUID = ""
            userUID = auth.currentUser?.uid || ""
            let userToken = localStorage.getItem('user_token') || ""
            let eventId = id || ""
            if (userToken != null && userToken != "" && eventId != ""){
              fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAuthenticatedGetOrganizerDetailedEvents?event_id=${eventId}&full_data=${true}&mode=orders`,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + userToken
              }, }).then((response) => {
                  const responseTask = response.json()
                  responseTask.then((data) => {
                      let dashboardItem = eventDashboard
                      if (data.success != null && data.success){
                          //load data
                          
                          if (data.currentTime != null){
                              dashboardItem.currentTime = data.currentTime
                          }
                          
                          if (data.overview != null){
                              console.log(data.overview)
                              let overview = {...data.overview}
                              dashboardItem.dashboardItems = [
                                  {
                                    title: 'Total Sales',
                                    total: overview.totalSales + "CAD",
                                    time: dashboardItem.currentTime,
                                    notice: '',
                                  },
                                  {
                                    title: 'Tickets Sold',
                                    total: overview.tickets,
                                    time: dashboardItem.currentTime,
                                    notice: '',
                                  },
                                  {
                                    title: 'Total Members',
                                    total: overview.members,
                                    time: dashboardItem.currentTime,
                                    notice: '',
                                  },
                                  {
                                    title: 'Page Views',
                                    total: overview.views,
                                    time: dashboardItem.currentTime,
                                    notice: '',
                                  },
                                  {
                                    title: 'Total Form Submissions',
                                    total: overview.formSubmissions,
                                    time: dashboardItem.currentTime,
                                    notice: '',
                                  },
                                  {
                                    title: 'Total Invoices',
                                    total: overview.invoices,
                                    time: dashboardItem.currentTime,
                                    notice: '',
                                  },
                                ]
                          }
                          
                          if (data.event != null){
                              dashboardItem.eventItem = data.event
                              if (data.event.prices != null){
                                let pricesArray: EventPriceDataType[] = []
                                pricesArray = Object.values(data.event.prices)
                              let finalPriceArray = pricesArray.sort((a,b)=>{
                                return a.order - b.order
                              })
                              setEventPrices(finalPriceArray)
                              }
                          }
                          if (data.seats != null){
                            loadAllOrderSeats(eventId)
                            // let seatData: EventSeatType[] = []
                            // if (data.seats.data != null){
                              
                            //   setOrderSeats(data.seats.data)
                            // }
                          }
                          if (data.orders != null){
                            let orderData: EventOrderType[] = []

                              if (data.orders.data != null){
                                  dashboardItem.orders = data.orders.data
                                  setAllOrders(data.orders.data)
                                  setDisplayOrders(data.orders.data)
                                  orderData = data.orders.data
                              }
                              if (data.orders.headers != null){
                                let orderHeaders = [{ field: "id",  headerName: "Order ID" }]
                                orderHeaders = data.orders.headers
                                setOrderColumn(data.orders.headers)
                                let orderCSV: CSVHeaderType[] = []
                                orderHeaders.forEach((orderItm) => {
                                  orderCSV.push({key: orderItm.field, label: orderItm.headerName})
                                })
                                setOrderCSVHeader(orderCSV)
                              }
                              if (data.orders.promos != null){
                                let promos: EventPromoType[] = []
                                promos = data.orders.promos
                                setOrderPromos(promos)
                              }
                          }
                          if (data.tickets != null){
                            if (data.tickets.data != null){
                              dashboardItem.tickets = data.tickets.data
                              setOrderTickets(data.tickets.data)
                            }
                          }
                          
                          setCurrentTime(dashboardItem.currentTime)
                          setEventDashboard(dashboardItem)
                      }
                      else{
                        console.log(data)
                        if (data.error == "token"){
                          auth.currentUser?.getIdToken(true).then((userToken) => {
                            localStorage.setItem('user_token',userToken)
                            window.location.reload()
                          })
                        }
                        
                      }
                      

                  })
              })
            }
          else{

          }

  }, [eventDashboard]);

  const [orderColumn, setOrderColumn] = useState([
    { field: "id",  headerName: "Order ID" },
    { field: "name",  headerName: "Name" },
    { field: "userEmail",  headerName: "Email" },
    { field: "amount",  headerName: "Amount (CAD)" },
    { field: "tickets",  headerName: "Tickets" },
    { field: "lang",  headerName: "Lang" },
    { field: "couponCode",  headerName: "Coupon" },
    { field: "couponDesc",  headerName: "Coupon Desc" },
    { field: "ipAddress",  headerName: "IP"},
    { field: "ipLocation",  headerName: "IP Location"},
    { field: "timestamp",  headerName: "Date" },
  ])

  const [promoColumn, setPromoColumn] = useState([
    { field: "id",  headerName: "Coupon Code" },
    { field: "promoType",  headerName: "Coupon Type" },
    { field: "amount",  headerName: "Amount" },
    { field: "redeem",  headerName: "Redeem Type"},
    { field: "redeemTimes",  headerName: "Redeems"},
    { field: "promotionCode",  headerName: "Promo Ref"},
    { field: "validated",  headerName: "Validation"},
    { field: "created",  headerName: "Creation Date"},
  ])

  const [seatColumn, setSeatColumn] = useState([
    { field: "id",  headerName: "Seat Id" },
    { field: "name",  headerName: "Seat Name" },
    { field: "status",  headerName: "Status" },
    { field: "ticketId",  headerName: "Ticket ID" },
    { field: "ticketName",  headerName: "Ticket Name" },
  ])
  

  const [ticketColumn, setTicketColumn] = useState([
    { field: "id", flex: 0.3,  headerName: "Ticket ID" },
    { field: "userName", flex: 0.3,  headerName: "Name" },
    { field: "ownerName", flex: 0.3,  headerName: "Owner Name" },
    { field: "ticketName", flex: 0.5,  headerName: "Ticket Name" },
    { field: "status", flex: 0.3,  headerName: "Status" },
    { field: "checkin", flex: 0.3,  headerName: "Check-Ins" },
    { field: "notes", flex: 0.5,  headerName: "Notes" },
    { field: "seat", flex: 0.3,  headerName: "Seat" },
    { field: "timestamp", flex: 0.4,  headerName: "Date" },
  ])

  const [inviteColumn, setInviteColumn] = useState([
    { field: "id",  headerName: "Invite ID" },
    { field: "firstName",  headerName: "First Name" },
    { field: "lastName",   headerName: "Last Name" },
    { field: "email",  headerName: "Email" },
    { field: "orderId", headerName: "Order" },
    { field: "status", headerName: "Status" },
    { field: "url", headerName: "Invite URL" },
    { field: "timestamp", headerName: "Created Date" },
  ])

  const [requestColumn, setRequstColumn] = useState([
    { field: "id",  headerName: "Request ID" },
    { field: "firstName",  headerName: "First Name" },
    { field: "lastName",   headerName: "Last Name" },
    { field: "email",  headerName: "Email" },
    { field: "formSubmissionId", headerName: "Form" },
    { field: "status", headerName: "Status" },
    { field: "orderId", headerName: "Order" },
    { field: "fromUA", headerName: "User Agent" },
    { field: "timestamp", headerName: "Created Date" },
  ])
  const [selectedRequest, setSelectedRequest] = useState<OrderRequestType>()

  const [requestCSVHeader, setRequestCSVHeader] = useState<CSVHeaderType[]>([
    { key: "id",  label: "Request ID" },
    { key: "firstName",  label: "First Name" },
    { key: "lastName",  label: "Last Name" },
    { key: "email",  label: "Email" },
    { key: "status",  label: "Status" },
    { key: "orderId",  label: "Order ID" },
    { key: "timestamp",  label: "Created Date" },
  ])

  const [orderCSVHeader, setOrderCSVHeader] = useState<CSVHeaderType[]>([
    { key: "id",  label: "Order ID" },
    { key: "name",  label: "Name" },
    { key: "userEmail",  label: "Email" },
    { key: "amount",  label: "Amount (CAD)" },
    { key: "tickets",  label: "Tickets" },
    { key: "lang",  label: "Lang" },
    { key: "couponCode",  label: "Coupon" },
    { key: "couponDesc",  label: "Coupon Desc" },
    { key: "ipAddress",  label: "IP"},
    { key: "ipLocation",  label: "IP Location"},
    { key: "timestamp",  label: "Date" },
  ])

  async function changeTicket(ticketId:string,notes:string){

  }

  async function cancelOrder(orderId:string){
    let url = `https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPICancelOrder?order_id=${orderId}`
    const userRefToken = await auth.currentUser?.getIdToken(true)
    let userToken = userRefToken
    if (userToken != null && userToken != ""){
      localStorage.setItem('user_token',userToken)
      fetch(url,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + userToken
        },
      }).then((response) => {
        const responseTask = response.json()
        responseTask.then((data) => {
          if (data.success != null && data.success){
            setAttendApproveLoading(false)
            setAttendRequestOpen(false)
            setOrderOpen(false)
            window.alert('Order cancelled')
            window.location.reload()
          }
          else{
            if (data.error != null){
              if (data.error == "token"){
                auth.currentUser?.getIdToken(true).then((userToken) => {
                  localStorage.setItem('user_token',userToken)
                  window.location.reload()
                })
              }
            }
          }
        })
      })
    }
    else{

    }
  }

  async function acceptInvitation(inviteCode: string, firstName: string, lastName: string, form_id: string, uid: string){
    fetch(
      "https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPIEventFetchAndAcceptPrivateInvite",{
  method: "POST",
  headers: {
      "Content-Type": "application/json",
  },
  body: JSON.stringify({
      event_id: id || "",
      invite_code: inviteCode,
      first_name: firstName,
      last_name: lastName,
      status: 'accepted',
      uid: uid,
      formId: form_id,
  }),}).then((response) => {
      const responseTask = response.json();
      responseTask.then((data) => {
          if (data.success) {
          }
          else{
              //failed
          }
      })
  })

  }

  async function sendOrderEmail(orderId:string, name: string){
    const url = `https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPISendOrderEmail?order_id=${orderId}&user_name=${name}`
    fetch(url, {
      headers: {
          "Content-Type": "application/json",
        }
      }).then((data)=>{
        setAttendApproveLoading(false)
        setAttendRequestOpen(false)
        setOrderOpen(false)
        alert("Email sent successfully")
      }).catch((e)=>{
    })
  }

  async function attendRequestApproveByAdminFreeTicket(eventId:string, requestId: string, pwd:string, quantity:string, priceId:string){
    const url = `https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPICreateFreeTicketsByRequestId?event_id=${eventId}&request_id=${requestId}&price_id=${priceId}&pwd=${pwd}&quantity=${quantity}`
    fetch(url, {
      headers: {
          "Content-Type": "application/json",
        }
      }).then((data)=>{
        setAttendRequestQuantity("1")
        setAttendRequestPrice(undefined)
        setAttendRequestOpen(false)
        setAttendApproveLoading(false)
      }).catch((e)=>{

      })
  }

  async function attendRequestRejectByAdmin(eventId:string,requestId:string,pwd:string){
    const url = `https://eventsapineweventrequestrejectrequest-verqhozorq-uc.a.run.app/?event_id=${eventId}&request_id=${requestId}&password=${pwd}`
    fetch(url, {
            headers: {
                "Content-Type": "application/json",
              }
            }).then((data)=>{
              setAttendRequestOpen(false)
              
            }).catch((e)=>{

            })
  }

  const [orderRow, setOrderRow] = useState<string>('{}')
  const [orderSelectedTickets, setOrderSelectedTickets] = useState<EventTicketType[]>([])
  const [orderSelectedTicket, setOrderSelectedTicket] = useState<EventTicketType>()
  const [orderTickets, setOrderTickets] = useState<EventTicketType[]>([])
  const [orderOpen, setOrderOpen] = useState(false)
  const [attendRequestOpen, setAttendRequestOpen] = useState(false)
  const [attendApproveLoading, setAttendApproveLoading] = useState(false)
  const [attendRequestQuantity, setAttendRequestQuantity] = useState<string>("1")
  const [attendRequestPrice, setAttendRequestPrice] = useState<EventPriceDataType>()
  const [newAttendeeOpen, setNewAttendeeOpen] = useState(false)
  const [searchW, setSearchW] = useState('')
  const [displayOrders, setDisplayOrders] = useState<EventOrderType[]>([])
  
  const [ticketItemOpen, setTicketItemOpen] = useState<boolean>(false)
  const [ticketNotes, setTicketNotes] = useState<string>('')
  const [ticketTransferData, setTicketTransferData] = useState({
    name: '',
    email: ''
  })
  const [transferInProgress, setTransferInProgress] = useState(false)
  const TicketItemDrawer = (
    <Drawer open={ticketItemOpen} onClose={()=>{ setTicketItemOpen(false) }}>
    <Box 
   role="presentation" 
   style={{width: isMobile ? '100vw' : '50vw'}}
   >
     <div className="relative space-y-8" style={{margin: '16px'}}>
     <ButtonSecondary className="ml-3" onClick={()=>{
       setTicketItemOpen(false)
       setOrderSelectedTicket(undefined)
     }}>Back</ButtonSecondary>
   </div>
   <div className="relative space-y-2" style={{margin: '16px'}}>
          <div className="text-2xl font-bold">Ticket Details</div>
          <br/>
          <div>
            {orderSelectedTicket && <>
            <div>Ticket ID: <b>{orderSelectedTicket.ticketId}</b> </div>
            <div>Order ID: <b>{orderSelectedTicket.orderId}</b></div>
            <div>Ticket Name: <b>{orderSelectedTicket.ticketName}</b></div>
            <div>Customer Name: <b>{orderSelectedTicket.userName}</b></div>
            <div>Owner Name: <b>{orderSelectedTicket.ownerName}</b></div>
            <div>Status: <b>{orderSelectedTicket.status}</b></div>
            <br/><br/>
            <div style={{marginTop: '25px', marginBottom: '25px'}}>
               <div className="text-2xl font-semibold">Transfer Ticket</div>
               <FormItem label="Email Address">
                 <Input placeholder="" type="email" defaultValue={ticketTransferData.email} onChange={(e)=>{
                   const emailInput = e.target.value
                   setTicketTransferData({...ticketTransferData, email: emailInput})
                 }}/>
               </FormItem>
               <FormItem label="Name">
                 <Input placeholder="" defaultValue={ticketTransferData.name} type='text' onChange={(e)=>{
                   const nameInput = e.target.value
                   setTicketTransferData({...ticketTransferData, name: nameInput})
                 }}/>
               </FormItem>
               <ButtonPrimary className="mt-3" loading={transferInProgress} disabled={transferInProgress} onClick={()=>{
                 //transfer ticket
                if (ticketTransferData.name != "" && ticketTransferData.email != "" && orderSelectedTicket.orderId != ""){
                  setTransferInProgress(true)
                  ticketTransferAction(
                    orderSelectedTicket.ticketId,
                    orderSelectedTicket.orderId,
                    ticketTransferData.email,
                    ticketTransferData.name)
                    setTicketTransferData({...ticketTransferData, name: "", email: ""})
                }
                else{
                  alert("Please fill out correct information")
                  setTransferInProgress(false)
                }
               }}>Transfer Ticket</ButtonPrimary>
              </div>
              <div style={{marginTop: '25px', marginBottom: '25px'}}>
              <div className="text-2xl font-semibold">Add Notes</div>
              <FormItem label="Notes">
                 <Input placeholder="" type="text" defaultValue={ticketNotes} onChange={(e)=>{
                   const textInput = e.target.value
                   setTicketNotes(textInput)
                 }}/>
               </FormItem>
               <ButtonPrimary className="mt-3" loading={transferInProgress} disabled={transferInProgress} onClick={()=>{
                 
               }}>
                 Add Notes
               </ButtonPrimary>
              </div>
            </>
            }
          </div>
  </div>
   </Box>
   </Drawer>
  )
  const AddNewAttendeeDrawer = (
    <Drawer open={newAttendeeOpen} onClose={()=>{ setNewAttendeeOpen(false)}}>
       <Box 
      role="presentation" 
      style={{width: isMobile ? '100vw' : '50vw'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setNewAttendeeOpen(false)
        }}>Back</ButtonSecondary>
      </div>
      </Box>
      </Drawer>
  )

  const [selectedInvitation, setSelectedInvitation] = useState<EventPrivateInviteDataType>({
    id: '',
    inviteCode: '',
    email: '',
    firstName: '',
    lastName: '',
    priceId: '',
    phone: '',
    quantity: 1,
    status: '',
    orderId: '',
    url: '',
    timestamp: '',
  })
  const [invitationDetailOpen, setInvitationDetailOpen] = useState(false)
  const InvitationDetailList = (
    <Drawer open={invitationDetailOpen} onClose={()=>{ setInvitationDetailOpen(false)}}>
      <Box 
      role="presentation" 
      style={{width: isMobile ? '100vw' : '50vw'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setInvitationDetailOpen(false)
        }}>Back</ButtonSecondary>
      </div>

      <div className="relative space-y-2" style={{margin: '16px'}}>
          <div className="text-2xl font-bold">Invitation Details</div>
          <br/>
          <div>
            {selectedInvitation && <>
            <div>Invite Code: <b>{selectedInvitation.inviteCode}</b> </div>
            <div>First Name: <b>{selectedInvitation.firstName}</b></div>
            <div>Last Name:  <b>{selectedInvitation.lastName}</b></div>
            <div>Email:  <b>{selectedInvitation.email}</b></div>
            <div>Status:  <b>{selectedInvitation.status}</b></div>
            <div>OrderId: <b>{selectedInvitation.orderId}</b></div>
            <div>Invite Date: <b>{selectedInvitation.timestamp}</b></div>
            <br/>
            </>
            }
          </div>
        </div>

      </Box>
      </Drawer>
  )

  const SeatDetailList = (
    <Drawer open={isSeatDetailOpen} onClose={()=>{ setIsSeatDetailOpen(false) }}>
      <Box 
      role="presentation" 
      style={{width: isMobile ? '100vw' : '50vw'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setIsSeatDetailOpen(false)
        }}>Back</ButtonSecondary>
      </div>

      <div className="relative space-y-2" style={{margin: '16px'}}>
          <div className="text-2xl font-bold">Seat Details</div>
          <br/>
          <div>
            {selectedSeat && <>
            <div>Seat Name: <b>{selectedSeat.name}</b> </div>
            <div>Status: <b>{selectedSeat.status}</b></div>
            <div>Ticket Name:  <b>{selectedSeat.ticketName}</b></div>
            <div>Ticket ID:  <b>{selectedSeat.ticketId}</b></div>
            <br/>
            </>
            }
          </div>
          <div className="text-2xl font-semibold mt-5">Actions</div>
          {selectedSeat && selectedSeat.status == 'pending' && <ButtonPrimary onClick={()=>{
            //lock seat
            let confirmed = window.confirm("Do you want to lock the seat?")
            if (confirmed){
              changeSeatOperation('reserve',id || "",selectedSeat.id)
            }
            
          }}>Lock Seat</ButtonPrimary>}
          {selectedSeat && selectedSeat.status == 'reserved' && <ButtonPrimary onClick={()=>{
            let confirmed = window.confirm("Do you want to open the seat?")
            if (confirmed){
              changeSeatOperation('open',id || "",selectedSeat.id)
            }
          }}>Open Seat</ButtonPrimary>}
        </div>
      </Box>
      </Drawer>
  )

  const AttendRequestList = (
    <Drawer open={attendRequestOpen} onClose={()=>{ setAttendRequestOpen(false)}}>
      <Box 
      role="presentation" 
      style={{width: isMobile ? '100vw' : '50vw'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setAttendRequestOpen(false)
        }}>Back</ButtonSecondary>
      </div>

      <div className="relative space-y-2" style={{margin: '16px'}}>
          <div className="text-2xl font-bold">Request Details</div>
          <br/>
          <div>
            {selectedRequest && <>
            <div>Requested ID: <b>{selectedRequest.id}</b> </div>
            <div>First Name: <b>{selectedRequest.firstName}</b></div>
            <div>Last Name:  <b>{selectedRequest.lastName}</b></div>
            <div>Email:  <b>{selectedRequest.email}</b></div>
            <div>Status:  <b>{selectedRequest.status}</b></div>
            <div>Requested Date: <b>{selectedRequest.date}</b> </div>
            <div>OrderId: <b>{selectedRequest.orderId}</b></div>
           
            <br/>
            <div>Price Type: <b>{selectedRequest.priceId}</b></div>
            <div>Quantity: <b>{selectedRequest.quantity}</b></div>
            <br/>
            <div>Questions:</div>
            { selectedRequest.form.map((foItm) => {
              return <div>
                <div>{foItm.title}</div>
              <div><b>{foItm.value}</b></div>
              </div>
            })}
            </>}
          </div>
          <div className="mt-5">
            <FormItem desc="Select Ticket Type" label="Ticket Type *">
            <Select onChange={(e)=>{
              let priceId = e.target.value || ""
              const attendReqPriceItm = eventPrices.filter((priceItm)=>{
                return priceItm.id == priceId
              })
              if (attendReqPriceItm.length > 0){
                setAttendRequestPrice(attendReqPriceItm[0])
              }
            }}>
               <option value={''}>{'--- select ticket ---'}</option>
              {
              eventPrices.map((itm)=>{
                return <option value={itm.id}>{itm.priceDescription}</option>
              })
              }
              </Select>
            </FormItem>
          </div>
          <div className="mt-5">
            <FormItem desc="Select Quantity" label="Ticket Quantity *">
            <Select onChange={(e)=>{
                            
                            let quanV = e.target.value || "1"
                            setAttendRequestQuantity(quanV)
                            
              }}>
                          <option value={'1'}>{'1'}</option>
                          <option value={'2'}>{'2'}</option>
                          <option value={'3'}>{'3'}</option>
                          <option value={'4'}>{'4'}</option>
                          <option value={'5'}>{'5'}</option>
              </Select>
            </FormItem>
          </div>
          {selectedRequest?.orderId != "" && <div className="mt-5">
            <ButtonPrimary disabled={attendApproveLoading} loading={attendApproveLoading} onClick={()=>{
              setAttendApproveLoading(true)
              let ordrId = selectedRequest?.orderId || ""
              sendOrderEmail(ordrId, selectedRequest?.firstName || "")
            }}>Send Email Again</ButtonPrimary></div>}
          {selectedRequest?.orderId == "" && <div className="mt-10">
            {eventDashboard.eventItem.price == 0 && <ButtonPrimary loading={attendApproveLoading} onClick={()=>{
              //
              let priceId = ""
              if (attendRequestPrice != null){
                priceId = attendRequestPrice.id
              }
              if (eventDashboard.eventItem.pin != null && eventDashboard.eventItem.pin != "" && priceId != ""){
                setAttendApproveLoading(true)
                attendRequestApproveByAdminFreeTicket(
                  eventDashboard.eventItem.id,
                  selectedRequest?.id || "",
                  eventDashboard.eventItem.pin,
                  attendRequestQuantity,
                  priceId)
                
              }
              else{
                alert('Please select ticket type')
              }
            }}>Approve and Send Ticket</ButtonPrimary>}
            {eventDashboard.eventItem.price > 0 && <ButtonPrimary loading={attendApproveLoading} onClick={()=>{
              //
              let priceId = attendRequestPrice?.id || ""
              if (eventDashboard.eventItem.pin != null && eventDashboard.eventItem.pin != "" && priceId != ""){
                setAttendApproveLoading(true)
                

                attendRequestApproveByAdminFreeTicket(
                  eventDashboard.eventItem.id,
                  selectedRequest?.id || "",
                  eventDashboard.eventItem.pin,
                  attendRequestQuantity,
                  priceId)
              }
              else{
                alert('Please select ticket type')
              }
            }}>Approve and Send Payment Link</ButtonPrimary>}
            <ButtonSecondary onClick={()=>{
              if (eventDashboard.eventItem.pin != null && eventDashboard.eventItem.pin != ""){
                attendRequestRejectByAdmin(
                  eventDashboard.eventItem.id,
                  selectedRequest?.id || "",
                  eventDashboard.eventItem.pin)
              }
            }}>Reject</ButtonSecondary>
          </div>}
      </div>
      </Box>
    </Drawer>
  )
  const OrderDrawerList = (
    <Drawer open={orderOpen} onClose={()=>{ setOrderOpen(false)}}>
       <Box 
      role="presentation" 
      style={{width: '50vw'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setOrderOpen(false)
        }}>Back</ButtonSecondary>
      </div>

      <div className="relative space-y-2" style={{margin: '16px'}}>
          <div className="text-2xl font-bold">Order Details</div>
          <br/>
          {Object.keys(JSON.parse(orderRow) || {}).map((keyItm) => {
            let headerN = ""
            let orderRowVal = {}
            orderRowVal = JSON.parse(orderRow) || {}
            let orderHeaderN = orderColumn.filter((itm) => {
              return itm.field == keyItm
            })
            if (orderHeaderN.length > 0){
              headerN = orderHeaderN[0].headerName
            }
            return headerN && <div>
            <span>{headerN}: </span>
            <span className="font-bold">{(JSON.parse(orderRow) || {})[keyItm]}</span>  
          </div>
          }) }
          <div className="text-xl font-semibold">Tickets ({orderSelectedTickets.length})</div>
          {
            orderSelectedTickets.map((tickItm) => {
              return <div>
                <span>{tickItm.ticketId}<b>({tickItm.ticketName})</b></span><br/>
              </div>
            })
          }

        <div className="text-2xl font-semibold mt-5">Actions</div>
        <div className="text-xl font-semibold mt-3">Status: {currentOrder.status}</div>
          {currentOrder.id != "" && (currentOrder.status != "cancelled" && currentOrder.status != "refunded") && <div className="mt-7">
            <ButtonPrimary disabled={attendApproveLoading} loading={attendApproveLoading} onClick={()=>{
              setAttendApproveLoading(true)
              sendOrderEmail(currentOrder.id, currentOrder.name || "Customer")
            }}>Send Email</ButtonPrimary>
            <ButtonDanger className="ml-5" disabled={attendApproveLoading} loading={attendApproveLoading} onClick={()=>{
              let confirmCancel = window.confirm("Do you want to cancel current order?")
              if (confirmCancel){
                setAttendApproveLoading(true)
                cancelOrder(currentOrder.id)
              }
              else{

              }
            }}>Cancel Order</ButtonDanger></div>
          }

          

          {/* <div className="mt-5">
            <ButtonPrimary onClick={()=>{
              setOrderOpen(false)
            }}>Send Email Again</ButtonPrimary></div> */}
          {/* <div>
            <span>Customer Name:  </span>
            <span className="font-bold">A</span>  
          </div>
          <div>
            <span>Name:  </span>
            <span className="font-bold">A</span>  
          </div>
          <div>
            <span>Name:  </span>
            <span className="font-bold">A</span>  
          </div>
          <div>
            <span>Name:  </span>
            <span className="font-bold">A</span>  
          </div> */}
        </div>

      </Box>
    </Drawer>
  )

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle={eventDashboard.eventItem.title}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          
          <Tab.Group onChange={handleTabChangEvent}>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                { OrderDrawerList }
                
                <div className="flex align-center justify-between mt-3">
                  <h2 className="text-3xl font-semibold">Orders ({displayOrders.length})</h2>
                  {false && 
                  <CSVLink 
                  className="!leading-none" 
                  filename="event-order-data.csv" 
                  style={{}} 
                  data={eventDashboard.orders} 
                  headers={orderCSVHeader}>
                    <span className="font-semibold text-1xl">Download Order Data</span>
                  </CSVLink>}
                </div>
              

{/* <!--                 <div className="mt-5 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                  <DataGrid 
                    checkboxSelection
                    onCellClick={(e)=>{
                      let orderId = ""
                      if (e.row != null){
                        if (e.row.id != null){
                          orderId = e.row.id
                        }
                      }
                      let rowObj = {
                        userName: '',
                        userEmail: '',
                        uniqueId: '',
                        paymentId: '',
                        id: ''
                      }
                      rowObj = e.row
                      setOrderRow(JSON.stringify(rowObj))
                      // let filteredOrder = allOrders.filter((ord) => {
                      //   return ord.uniqueId == orderId
                      // })
                      // if (filteredOrder.length > 0){
                      //   setCurrentOrder(filteredOrder[0])
                      // }
                      setOrderOpen(true)
                    }}
                    sx={{ overflowX: 'scroll' }}
                    rows={eventDashboard.orders} 
                    columns={orderColumn} /> --> */}

          <div className="mt-5 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <FormItem label="" className="mb-5 mt-3">
            <Input type={'text'} placeholder="Search orders with order number, email, and name" onChange={(e)=>{
              let seacW = e.target.value
              if (seacW != ""){
                setSearchW(seacW)
              }
            }}/>
            <div className="mt-2 mb-5">
              <ButtonPrimary className="mr-2" onClick={()=>{
                //apply filter
                if (searchW != ""){
                  let newOd = allOrders.filter((orderItm)=>{
                    let couponC = ""
                    let name = ""
                    let email = ""
                    
                    if (orderItm.couponCode != null){
                      couponC = orderItm.couponCode
                    }
                    if (orderItm.name != null){
                      name = orderItm.name
                    }
                    if (orderItm.userEmail != null){
                      email = orderItm.userEmail
                    }
                    
                    return email.toLowerCase().includes(searchW.toLowerCase()) || 
                    orderItm.id.includes(searchW.toLowerCase()) || 
                    name.toLowerCase().includes(searchW.toLowerCase()) || 
                    couponC.toLowerCase().includes(searchW.toLowerCase())
                  })
                  alert(`Displaying ${newOd.length} orders`)
                  setDisplayOrders(newOd)
                }
              }}>Search</ButtonPrimary>
              <ButtonSecondary className="" onClick={()=>{
                setDisplayOrders(allOrders)
                setSearchW('')
              }}>Clear</ButtonSecondary>
            </div>
            
          </FormItem>
          <DataGrid 
            onCellClick={(e)=>{
              setOrderSelectedTickets([])
              let orderId = ""
              if (e.row != null){
                if (e.row.id != null){
                  orderId = e.row.id
                }
              }
              let rowObj = {
                userName: '',
                userEmail: '',
                uniqueId: '',
                paymentId: '',
                id: ''
              }
              rowObj = e.row
              setOrderRow(JSON.stringify(rowObj))

              let selectOrders = displayOrders.filter((orderD) => {
                return orderD.id == orderId
              })
              if (selectOrders.length > 0){
                setCurrentOrder(selectOrders[0])
              }
              setOrderSelectedTickets(orderTickets.filter((ticketItm) => {
                return ticketItm.orderId == orderId
              }))
              setOrderOpen(true)
            }}
            sx={{ overflowX: 'scroll' }}
        rows={displayOrders} 
        columns={orderColumn} />

              </Tab.Panel>
              <Tab.Panel>
              { TicketItemDrawer }
                <h2 className="text-3xl font-semibold">Tickets ({orderTickets.length})</h2>
                <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <DataGrid 
                  checkboxSelection 
                  onCellClick={(e)=>{
                    let ticketId = ""
                    if (e.row != null){
                      if (e.row.id != null){
                        ticketId = e.row.id
                      }
                    }
                    const selectedT = orderTickets.filter((ticketItm) => {
                      return ticketId == ticketItm.id
                    })
                    if (selectedT.length > 0){
                      setOrderSelectedTicket(selectedT[0])
                      
                    }
                    setTicketItemOpen(true)
                  }}
                  sx={{ overflowX: 'scroll' }}
                  rows={orderTickets} 
                  columns={ticketColumn} />
              </Tab.Panel>
              
              {/* Order Coupons */}
              <Tab.Panel>
                <h2 className="text-3xl font-semibold">Coupons ({orderPromos.length})</h2>
                <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <DataGrid 
                  checkboxSelection 
                  sx={{ overflowX: 'scroll' }}
                  rows={orderPromos} 
                  columns={promoColumn} />
              </Tab.Panel>

              <Tab.Panel>
                { InvitationDetailList }
                <div className="flex flex-row justify-between">
                  <h2 className="text-3xl font-semibold">Invitations ({inviteData.length})</h2>
                  <div className="flex flex-row gap-2">
                    <Button className="sm:px-20" onClick={openCSVInviteDialog}>Invite via CSV</Button>
                    <Button className="sm:px-20" onClick={openManualInviteDialog}>Manually</Button>
                    <IconButton
                      onClick={handleRefreshInviteTable}
                      disabled={isInviteTableRefreshing}
                      color="primary"
                      aria-label="refresh"
                    >
                      {isInviteTableRefreshing ? (
                        <CircularProgress size={24} />
                      ) : (
                        <RefreshOutlined />
                      )}
                    </IconButton>
                  </div>
                </div>
                <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                  <DataGrid 
                    sx={{ overflowX: 'scroll' }}
                    rows={inviteData} 
                    columns={inviteColumn} 
                    onCellClick={(e)=>{
                      let inviteCode = ""
                      if (e.row != null){
                        if (e.row.id != null){
                          inviteCode = e.row.id
                        }
                      }
                      if (inviteCode != ""){
                        let invitedData = inviteData.filter((inviteItm) => {
                          return inviteItm.inviteCode == inviteCode
                        })
                        if (invitedData.length > 0){
                          setSelectedInvitation(invitedData[0])
                          setInvitationDetailOpen(true)
                        }
                        
                      }
                    }}/>

              {/* <h2 className="text-3xl font-semibold">Invitations ({invitedData.length})</h2>
          <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <DataGrid 
            checkboxSelection
            onCellClick={(e)=>{
              
            }}
            sx={{ overflowX: 'scroll' }}
        rows={invitedData} 
        columns={inviteColumn} /> */}
              </Tab.Panel>
              <Tab.Panel>
                { AttendRequestList }
                <div className="flex align-center justify-between mt-3">
                  <h2 className="text-3xl font-semibold">Attend Requests ({requestData.length})</h2>
                  {false &&
                  <CSVLink 
                  className="!leading-none" 
                  filename="event-request-data.csv" 
                  style={{}} 
                  data={requestData} 
                  headers={requestCSVHeader}>
                    <span className="font-semibold text-1xl">Download Request Data</span>
                  </CSVLink>}
                </div>
          <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <DataGrid 
            sx={{ overflowX: 'scroll' }}
            onCellClick={(e)=>{
              let requestId = ""
              if (e.row != null){
                if (e.row.id != null){
                  requestId = e.row.id
                }
              }
              const filteredReqs = requestData.filter((reqItm) => {
                return reqItm.id == requestId
              })
              if (filteredReqs.length > 0){
                let orderRefId = ""
                if (filteredReqs[0].orderId != null){
                  orderRefId = filteredReqs[0].orderId
                }
                let requestPriceId = "default-price"
                if (filteredReqs[0].priceId != null){
                  requestPriceId = filteredReqs[0].priceId
                }
                let requestQuan = 1
                if (filteredReqs[0].quantity != null){
                  requestQuan = filteredReqs[0].quantity
                }
                setSelectedRequest({
                  email: filteredReqs[0].email,
                  firstName: filteredReqs[0].firstName,
                  lastName: filteredReqs[0].lastName,
                  status: filteredReqs[0].status,
                  form: filteredReqs[0].form,
                  date: filteredReqs[0].timestamp,
                  orderId: orderRefId,
                  id: filteredReqs[0].id,
                  priceId: requestPriceId,
                  quantity: requestQuan,
                })
              }
              setAttendRequestOpen(true)

            }}
        rows={requestData} 
        columns={requestColumn} />

              </Tab.Panel>
              <Tab.Panel>
                <h2 className="text-3xl font-semibold">Refund Requests (0)</h2>
                <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <DataGrid 
                  checkboxSelection 
                  sx={{ overflowX: 'scroll' }}
                  rows={[]} 
                  columns={orderColumn} />
              </Tab.Panel>
              {/* Order Coupons */}
              <Tab.Panel>
                { SeatDetailList }
                <h2 className="text-3xl font-semibold">Seats ({orderSeats.length})</h2>
                <br/>
                <br/>
                <div>
                  <div className="mt-1"><ButtonPrimary>{`Taken (${seatCategories.taken})`}</ButtonPrimary><span className="ml-1">The seat is taken | 座位被占了</span></div>
                  <div className="mt-1"><ButtonDanger>{`Reserved (${seatCategories.locked})`}</ButtonDanger><span className="ml-1">The seat is reserved | 座位是预留的</span></div>
                  <div className="mt-1"><ButtonSecondary>{`Available (${seatCategories.empty})`}</ButtonSecondary><span className="ml-1">The seat is available ｜ 座位是空的</span></div>
                </div>
                <br/>
                <br/>
                <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div
                  className={`grid gap-3 md:gap-4 grid-cols-6`}>
                    {orderSeats.map((seatItm)=>{
                      if (seatItm.status == 'pending'){
                        return <ButtonSecondary onClick={()=>{

                          let seatDesc = ""
                          seatDesc += "Seat: " + seatItm.name + "\n"
                          seatDesc += "Status: " + seatItm.status + "\n"
                          setSelectedSeat(seatItm)
                          setIsSeatDetailOpen(true)

                        }}>{seatItm.name}</ButtonSecondary>
                      }
                      else if (seatItm.status == 'reserved'){
                        return <ButtonDanger onClick={()=>{

                          let seatDesc = ""
                          seatDesc += "Seat: " + seatItm.name + "\n"
                          seatDesc += "Status: " + seatItm.status + "\n"
                          setSelectedSeat(seatItm)
                          setIsSeatDetailOpen(true)

                        }}>{seatItm.name}</ButtonDanger>
                      }
                      else{
                        return <ButtonPrimary onClick={()=>{

                          let seatDesc = ""
                          seatDesc += "Seat: " + seatItm.name + "\n"
                          seatDesc += "Status: " + seatItm.status + "\n"
                          setSelectedSeat(seatItm)
                          setIsSeatDetailOpen(true)

                        }}>{seatItm.name}</ButtonPrimary>
                      }
                    })}
                </div>
                {/* <DataGrid 
                  checkboxSelection 
                  sx={{ overflowX: 'scroll' }}
                  rows={orderSeats} 
                  columns={seatColumn} /> */}
              </Tab.Panel>
            </Tab.Panels>
            </Tab.Group>
           
        </div>
        {/* invite 对话框 */}
        <ManualInviteDialog
        isOpen={isManualInviteDialogOpen}
        showLoading={isManualInviteDialogOpen&&isInviting}
        onClose={closeManualInviteDialog}
        onSubmit={handleManualInviteSubmit}
      />
      <CSVInviteDialog
        isOpen={isInvitePanelVisible}
        showLoading={isInvitePanelVisible&&isInviting}
        onClose={closeCSVInviteDialog}
        onSubmit={handleCSVInviteSubmit}
      />
               
      </CommonLayout>
    </div>
  );
};

export default EventOrders;
