import Label from "./../../components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { DataGrid } from "@mui/x-data-grid";
import { auth, fs} from 'firebaseSetup'
import { Box, Paper, Button, IconButton, Typography, useTheme } from "@mui/material";
import useEventDashboardLoader from "hooks/useEventDashboardLoader";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Link } from "react-router-dom";
import SocialsList from "shared/SocialsList/SocialsList";
import Alert from '@mui/material/Alert';
import { EventOrderType } from "data/types";
import EventDataDisplayCard from "components/EventManagementCard/EventDataDisplayCard";

export interface EventManagementPageProps {
  className?: string;
  eventId?: string;
  eventTitle?: string;
}

const EventDashboard: FC<EventManagementPageProps> = ({ className = "", eventId = "", eventTitle = "Event Title Here" }) => {
    
    interface TicketPriceItem {
      qty: number,
      max: number,
      priceDescription: string,
      priceItemId: string,
      id: string,
      remaining: string,
    }
    
    const {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [dataLoad, setDataLoad] = useState("Loading")
    const [currentTime,setCurrentTime] = useState('')
    const [currentTickets, setCurrentTickets] = useState<TicketPriceItem[]>([])

    const [eventDashboard, setEventDashboard] = useState({
      dashboardItems: [
          {
              title: 'Loading',
              total: 0,
              time: '',
              notice: '',
              link: '',
          }
      ],
      eventItem: {
          title: 'Loading...',
          id: '',
          description: 'Loading...'
      },
      currentTime: 'Loading...',
      mode: 'orders',
      tickets: [],
      members: [],
      orders: [],
  })

  const orderColumn = [
    { field: "id",  headerName: "Order ID" },
    { field: "name",  headerName: "Name" },
    { field: "userEmail",  headerName: "Email" },
    { field: "amount",  headerName: "Amount (CAD)" },
    { field: "tickets",  headerName: "Tickets" },
    { field: "lang",  headerName: "Lang" },
    { field: "couponCode",  headerName: "Coupon" },
    { field: "ipAddress",  headerName: "IP"},
    { field: "ipLocation",  headerName: "IP Location"},
    { field: "timestamp",  headerName: "Date" },
  ]
    

    useEffect(() => {
      setCurrentTime("")
          let userUID = ""
            userUID = auth.currentUser?.uid || ""
            let userToken = localStorage.getItem('user_token') || ""
            let eventId = id || ""
            if (userToken != null && userToken != "" && eventId != ""){
              fetch("https://us-central1-blink-574af.cloudfunctions.net/eventsAPICheckTicketStatus?event_id="+eventId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    }).then((response) => {
      const responseTask = response.json()
      responseTask.then((data) => {
        if (data.success != null && data.success){

          if (data.currentPrices != null){
            let currentPrices = {'a': {
              count: 0,
              priceDescription: "General Admission"
            }}
            currentPrices = data.currentPrices
            if (data.currentTickets != null){
              let cTickets = {'a':0}
              cTickets = data.currentTickets
              let allTickets: TicketPriceItem[] = []
              Object.keys(cTickets).forEach((priceKey,index) => {
                let qty = 0
                qty = cTickets[priceKey as keyof typeof cTickets]
                let cPrice = currentPrices[priceKey as keyof typeof currentPrices]
                allTickets.push({
                  qty: qty,
                  priceDescription: cPrice.priceDescription,
                  priceItemId: priceKey,
                  max: cPrice.count,
                  id: `${index+1}`,
                  remaining: `${qty}/${cPrice.count}`
                })
              })
              setCurrentTickets(allTickets)
            }

            
          }
          
        }
      })
    })
              
              fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAuthenticatedGetOrganizerDetailedEvents?event_id=${eventId}&full_data=${true}&mode=orders`,{
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
                "Authorization": "Bearer " + userToken
              },
  
       }).then((response) => {
           const responseTask = response.json()
           responseTask.then((data) => {
               let dashboardItem = eventDashboard
               if (data.success != null && data.success){
                   //load data
                   if (data.currentTime != null){
                       dashboardItem.currentTime = data.currentTime
                   }
                   
                   if (data.overview != null){
                       let overview = {...data.overview}
                       dashboardItem.dashboardItems = [
                           {
                             title: 'Total Sales',
                             total: Number(overview.totalSales).toLocaleString('en-US',{style: 'currency', currency: 'CAD'}),
                             time: dashboardItem.currentTime,
                             notice: '',
                             link: '/manage-event-order/'+eventId+'?view=orders',
                           },
                           {
                             title: 'Tickets Sold',
                             total: overview.tickets,
                             time: dashboardItem.currentTime,
                             notice: '',
                             link: '/manage-event-order/'+eventId+'?view=tickets',
                           },
                           {
                             title: 'Total Members',
                             total: overview.members,
                             time: dashboardItem.currentTime,
                             notice: '',
                             link: '/manage-event-customer/'+eventId+'',
                           },
                           {
                             title: 'Page Views',
                             total: overview.views,
                             time: dashboardItem.currentTime,
                             notice: '',
                             link: '/manage-event-analytics/'+eventId+'',
                           },
                           {
                             title: 'Total Form Submissions',
                             total: overview.formSubmissions,
                             time: dashboardItem.currentTime,
                             notice: '',
                             link: '/manage-event-order/'+eventId+'?view=forms',
                           },
                           {
                             title: 'Total Invoices',
                             total: overview.invoices,
                             time: dashboardItem.currentTime,
                             notice: '',
                             link: '/manage-event-order/'+eventId+'?view=invoices',
                           },
                         ]
                   }
                   
                   if (data.event != null){
                       dashboardItem.eventItem = data.event
                   }
                   if (data.orders != null){
                       if (data.orders.data != null){
                          dashboardItem.orders = data.orders.data
                       }
                   }
                   setCurrentTime(dashboardItem.currentTime)
                   setEventDashboard(dashboardItem)
               }
               else{
                 //refresh token
                 if (data.error == "token"){
                  auth.currentUser?.getIdToken(true).then((userToken) => {
                    localStorage.setItem('user_token',userToken)
                    window.location.reload()
                   })
                 }
                 
               }
               

           })
       })


            }
          else{

          }

  }, [eventDashboard]);

   

  

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle={eventDashboard.eventItem.title}>
      <div className="mb-5 space-y-6 sm:space-y-8">
        {/* <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold">{'About Event'}</h2>
        </div>
        
        <p>Event Description</p> */}
        {currentTime && <Alert severity="success">
          Loaded: {currentTime}
        </Alert>}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      </div>

        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-2xl font-semibold">{'Dashboard'}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {eventDashboard.dashboardItems.map((dashB) => {
            return <EventDataDisplayCard size="default" increase={dashB.total.toString()} title={dashB.title} timestamp={dashB.time} link={dashB.link} />
          })}
          </div>

          {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {
        eventDashboard.dashboardItems.map((dashB) => {
          return <Grid key={dashB.title} size={{ xs: 2, sm: 4, md: 4 }}>
          <Paper 
            elevation={3} 
            style={{padding: '25px'}}>
            <p>{dashB.title}</p>
            <h2 style={{fontSize: 25, fontWeight: 'bold', marginBottom: 5, marginTop: 5}}>{dashB.total}</h2>
            <p>{dashB.notice}</p>
            <br/>
            <p>Last update: {dashB.time}</p>
          </Paper>
        </Grid>
        })
      }
</Grid> */}

<div className="space-y-6 sm:space-y-8">

          <h2 className="text-2xl font-semibold">Share</h2>
          <h2 className="text-1xl font-normal">Event Link</h2>
          <Link to={"/e/"+id}><p>{'https://evtgo.com/e/'+id}</p></Link>

          <SocialsList />

  </div>

  <div className="mt-5 space-y-6 sm:space-y-8">

<h2 className="text-2xl font-semibold">Sales by ticket types</h2>
  <DataGrid 
      checkboxSelection 
        sx={{ overflowX: 'scroll' }}
        rows={currentTickets} 
        columns={[
          { field: "id", headerName: "Type ID" },
          { field: "priceDescription",  headerName: "Ticket Type" },
          { field: "priceItemId",  headerName: "Item ID" },
          { field: "remaining",  headerName: "Sold" },
        ]} />
</div>
          
          <div className="space-y-6 sm:space-y-8">

          <h2 className="text-2xl font-semibold">Recent Orders</h2>
            <DataGrid 
                checkboxSelection 
                  sx={{ overflowX: 'scroll' }}
                  rows={eventDashboard.orders} 
                  columns={orderColumn} />
          </div>

          <div className="space-y-6 sm:space-y-8">

          <h2 className="text-2xl font-semibold">Other Actions</h2>
            
          </div>

         
          
        </div>
      </CommonLayout>
    </div>
  );
};

export default EventDashboard;
