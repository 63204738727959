import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { auth, fs} from "firebaseSetup"
import { updatePassword } from "firebase/auth"

const AccountPass = () => {

  const [newPasswords, setNewPasswords] = React.useState({
    pass: '',
    passRepeat: ''
  })
  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <h2 className="text-3xl font-semibold">Update your password</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className=" max-w-xl space-y-6">
            <div>
              <Label>Email Address</Label>
              <Input type="email" defaultValue={auth.currentUser?.email || ""} disabled className="mt-1.5" />
            </div>
            <div>
              <Label>New password</Label>
              <Input type="password" className="mt-1.5" onChange={(e)=>{
                const pass = e.target.value
                setNewPasswords({...newPasswords, pass: pass})
              }} />
            </div>
            <div>
              <Label>Confirm password</Label>
              <Input type="password" className="mt-1.5" onChange={(e)=>{
                const pass = e.target.value
                setNewPasswords({...newPasswords, passRepeat: pass})
              }}/>
            </div>
            <div className="pt-2">
              <ButtonPrimary onClick={()=>{
                //update password
                if (auth.currentUser != null && newPasswords.pass == newPasswords.passRepeat && newPasswords.pass != ""){
                  updatePassword(auth.currentUser,newPasswords.pass).then((passE)=>{
                    alert("Password updated successfully")
                  }).catch((e)=>{
                    alert("Update Failed! Something went wrong")
                  })
                }
                else{
                  alert("Update Failed! Something went wrong")
                }
              }}>Update password</ButtonPrimary>
            </div>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
