import Label from "components/Label/Label";
import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { Tab } from "@headlessui/react";
import CommonLayout from "./CommonLayout";
import {useEffect, useState, Fragment} from "react"
import { auth } from "firebaseSetup"
import { EventDataType, EventManagementDataType, EventOrderByEvent, EventOrderType, EventTemplateDataType, EventTicketType } from "data/types";
import EventCard from "components/EventCard/EventCard";
import EventManagementCard from "components/EventManagementCard/EventManagementCard";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import Avatar from "shared/Avatar/Avatar";
import {  Paper, Card, CardHeader, CardContent, CardMedia, CardActions, Skeleton, IconButton, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Grid from '@mui/material/Grid2';
import EventTemplateCard from "components/EventManagementCard/EventTemplateCard";

const AccountOrganization = () => {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
  })
    let [categories] = useState([
      "Events",
      "Orders",
      "Settings"]);

    const [orgFound, setOrgFound] = useState(false)

    const [orgData, setOrgData] = useState({
      name: 'Loading...',
      description: '',
      profileURL: '',
      id: '',
    })
    const [eventData, setEventData] = useState<EventManagementDataType[]>([])
    const [pastEventData, setPastEventData] = useState<EventManagementDataType[]>([])
    const [draftEvents, setDraftEvents] = useState<EventTemplateDataType[]>([])
    const [orderData, setOrderData] = useState<EventOrderType[]>([])
    const [ticketData, setTicketData] = useState<EventTicketType[]>([])
    const [orderByEvent, setOrderByEvent] = useState<EventOrderByEvent[]>([])
    const [orderSummary, setOrderSummary] = useState({
      totalRevenue: 0,
      totalOrder: 0,
      totalEmails: 0,
      totalTickets: 0,
    })
    const [orderByEventColumn, setOrderByEventColumn] = useState([
      {field: "id",  headerName: "Event ID", flex: 0.1},
      {field: "title", headerName: "Event Name", flex: 0.2},
      {field: "totalOrder",  headerName: "Total Orders", flex: 0.3},
      {field: "totalRevenue",  headerName: "Total Revenue", flex: 0.3},
      {field: "totalEmails",  headerName: "Total Members", flex: 0.3}])
      const [ordersColumn, setOrdersColumn] = useState([
        {field: "id",  headerName: "Order ID", flex: 0.1},
        {field: "userEmail", headerName: "User Email", flex: 0.3},
        {field: "userName",  headerName: "Customer Name", flex: 0.3},
        {field: "couponCode",  headerName: "Coupon Code", flex: 0.3},
        {field: "amount",  headerName: "Amount", flex: 0.3},
        {field: "eventId",  headerName: "Event ID", flex: 0.3},])

    const [nextEvent, setNextEvent] = useState<EventManagementDataType>({
      id: '',
      title: '',
      description: '',
                    startDateString: '',
                    endDateString: '',
                    photoURLs: [],
                    photoURL: '',
                    viewCount: 0,
                    location: '',
                    address: '',
                    price: 0,
                    status: 'draft',
    })


    function LoadingOrderView(){
      return (
        <Card sx={{ maxWidth: 345, m: 2 }}>
          <CardHeader
            avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
            title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }}/>}
            subheader={<Skeleton animation="wave" height={10} width="40%" />}
          />
          <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
          <CardContent>
            <React.Fragment>
                <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                <Skeleton animation="wave" height={10} width="80%" />
              </React.Fragment>
          </CardContent>
        </Card>
      )
    }

    function EventLoadingView(){
      return (
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              <LoadingOrderView />
              <LoadingOrderView />
              <LoadingOrderView />
              <LoadingOrderView />
              <LoadingOrderView />
              <LoadingOrderView />
              <LoadingOrderView />
              <LoadingOrderView />
        </div>
      )
    }

    function summarizeEventData(eventOrders: EventOrderType[], eventTickets: EventTicketType[]) {
      let summary = {
        totalRevenue: 0,
        totalOrder: 0,
        totalEmails: 0,
        totalTickets: 0,
      }
      let uniqueEmails: string[] = []
      let orderEve: EventOrderByEvent[] = []
      let eventOrderD = {'1':{id: '',title: '',}}
      eventOrders.forEach((orderItm,index)=>{
        summary.totalRevenue += orderItm.amount
        if (!uniqueEmails.includes(orderItm.userEmail)){
          uniqueEmails.push(orderItm.userEmail)
        }
        const orderEveIndex = orderEve.findIndex((orderEveItem) => {
          return orderEveItem.id == orderItm.eventId
        })
        if (orderEveIndex >= 0){
          let orderEveItm = orderEve[orderEveIndex]
          orderEveItm.totalEmails += 1
          orderEveItm.totalOrder += 1
          orderEveItm.totalRevenue += orderItm.amount
          orderEve[orderEveIndex] = orderEveItm
        }
        else{
          orderEve.push({
            id: orderItm.eventId,
            title: orderItm.title,
            totalEmails: 1,
            totalOrder: 1,
            totalRevenue: orderItm.amount,
          })
        }
        
      })
      summary.totalEmails = uniqueEmails.length
      summary.totalOrder = eventOrders.length
      summary.totalTickets = eventTickets.length

      setOrderSummary(summary)
      orderEve.sort((orderEA, orderEB) => {
        return orderEB.totalOrder - orderEA.totalOrder
      })
      setOrderByEvent(orderEve)
    }

    async function loadOrgDetails(){
      const userUID = localStorage.getItem("user_uid")
      auth.currentUser?.getIdToken(true).then((userToken) => {
        localStorage.setItem('user_token',userToken)
        fetch("https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAPIFetchInfoAndEvents",{
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": "Bearer " + userToken
              },
          }).then((response) => {
            const responseTask = response.json();
            let orders: any[] = []
            let currentT: string = ""
            responseTask.then((data) => {
              if (data.status == "success"){
                  if (data.organizer != null){
                    setOrgData(data.organizer)
                    setOrgFound(true)
                  }
                  if (data.next_event != null){

                    let remoteItm = data.next_event
                    let eId = ""
                    let eTitle = ""
                              let eDescription = ""
                              let ePhotoURL = ""
                              let ePhotoURLs: string[] = []
                              let eAddress = ""
                              let eStartDateString = ""
                              let eEndDateString = ""
                              let ePrice = 0
                    if (remoteItm.id != null){
                      eId = remoteItm.id
                  }
                  if (remoteItm.title != null){
                      eTitle = remoteItm.title
                  }
                  if (remoteItm.description != null){
                      eDescription = remoteItm.description
                  }
                  if (remoteItm.startDateString != null){
                      eStartDateString = remoteItm.startDateString
                  }
                  if (remoteItm.endDateString != null){
                      eEndDateString = remoteItm.endDateString
                  }
                  if (remoteItm.photoURL != null){
                      ePhotoURL = remoteItm.photoURL
                  }
                  if (remoteItm.photoURLs != null){
                      ePhotoURLs = remoteItm.photoURLs
                      if (ePhotoURLs.length == 0){
                        ePhotoURLs = [ePhotoURL]
                      }
                  }
                  else{
                      ePhotoURLs = [ePhotoURL]
                  }
                  if (remoteItm.price != null){
                      ePrice = remoteItm.price
                  }
                  if (remoteItm.address != null){
                      if (remoteItm.address.fullAddress != null){
                          eAddress = remoteItm.address.fullAddress
                      }
                  }

                  let eventDataItem: EventManagementDataType = {
                    id: eId,
                    title: eTitle,
                    description: eDescription,
                    startDateString: eStartDateString,
                    endDateString: eEndDateString,
                    photoURLs: ePhotoURLs,
                    photoURL: ePhotoURL,
                    viewCount: 0,
                    location: remoteItm.location,
                    address: eAddress,
                    price: ePrice,
                    status: remoteItm.status,
                  }

                  setNextEvent(eventDataItem)





                  }
                  let orderDs: EventOrderType[] = []
                  let ticketDs: EventTicketType[] = []
                  if (data.orders != null){
                    if (data.orders.data != null){
                      orderDs = data.orders.data
                      setOrderData(orderDs)
                    }
                  }
                  if (data.tickets != null){
                    if (data.tickets.data != null){
                      ticketDs = data.tickets.data
                      setTicketData(ticketDs)
                    }
                  }

                  //summary
                  summarizeEventData(orderDs,ticketDs)

                  if (data.event_drafts != null){
                    if (data.event_drafts.data != null){
                      let eventDData: EventTemplateDataType[] = []
                      let allEvents = data.event_drafts.data

                      allEvents.forEach((remoteItm:any) => {
                        let eId = ""
                      let eTitle = ""
                      let eDescription = ""
                      let ePhotoURL = ""
                      let ePhotoURLs: string[] = []
                      let eAddress = ""
                      let eStartDateString = ""
                      let eEndDateString = ""
                      let ePrice = 0
                      let eLocation = ""

                      if (remoteItm.id != null){
                        eId = remoteItm.id
                    }
                    if (remoteItm.title != null){
                        eTitle = remoteItm.title
                    }
                    if (remoteItm.description != null){
                        eDescription = remoteItm.description
                    }
                    if (remoteItm.startDateStr != null){
                        eStartDateString = remoteItm.startDateStr
                    }
                    if (remoteItm.endDateStr != null){
                        eEndDateString = remoteItm.endDateStr
                    }
                    if (remoteItm.photoURL != null){
                        ePhotoURL = remoteItm.photoURL
                    }
                    if (remoteItm.photoURLs != null){
                        ePhotoURLs = remoteItm.photoURLs
                        if (ePhotoURLs.length == 0){
                          ePhotoURLs = [ePhotoURL]
                        }
                    }
                    if (remoteItm.location != null){
                      eLocation = remoteItm.location
                    }
                    else {
                      if (remoteItm.address != null){
                        if (remoteItm.address.fullAddress != null){
                          eLocation = remoteItm.address.fullAddress
                        }
                      }
                    }

                      let eventDataItem: EventTemplateDataType = {
                        id: eId,
                        title: eTitle,
                        description: eDescription,
                        startDateString: eStartDateString,
                        endDateString: eEndDateString,
                        photoURLs: ePhotoURLs,
                        photoURL: ePhotoURL,
                        location: eLocation,
                        address: eAddress,
                        price: ePrice,
                        status: "draft",
                      }

                      eventDData.push(eventDataItem)

                      })

                      setDraftEvents(eventDData)

                      


                      
                    }
                  }
                  if (data.events != null){
                      if (data.events.data != null){
                          let newEventData: EventManagementDataType[] = []
                          let pEventData: EventManagementDataType[] = []
                          let allEvents = data.events.data
                          allEvents.forEach((remoteItm: any) => {
                              let eId = ""
                              let eTitle = ""
                              let eDescription = ""
                              let ePhotoURL = ""
                              let ePhotoURLs: string[] = []
                              let eAddress = ""
                              let eStartDateString = ""
                              let eEndDateString = ""
                              let ePrice = 0

                              let eStatus = ""
                             
                              if (remoteItm.id != null){
                                  eId = remoteItm.id
                              }
                              if (remoteItm.title != null){
                                  eTitle = remoteItm.title
                              }
                              if (remoteItm.description != null){
                                  eDescription = remoteItm.description
                              }
                              if (remoteItm.startDateString != null){
                                  eStartDateString = remoteItm.startDateString
                              }
                              if (remoteItm.endDateString != null){
                                  eEndDateString = remoteItm.endDateString
                              }
                              if (remoteItm.photoURL != null){
                                  ePhotoURL = remoteItm.photoURL
                              }
                              if (remoteItm.photoURLs != null){
                                  ePhotoURLs = remoteItm.photoURLs
                                  if (ePhotoURLs.length == 0){
                                    ePhotoURLs = [ePhotoURL]
                                  }
                              }
                              else{
                                  ePhotoURLs = [ePhotoURL]
                              }
                              if (remoteItm.price != null){
                                  ePrice = remoteItm.price
                              }
                              if (remoteItm.address != null){
                                  if (remoteItm.address.fullAddress != null){
                                      eAddress = remoteItm.address.fullAddress
                                  }
                              }
                              if (remoteItm.status != null){
                                eStatus = remoteItm.status
                              }
                              let eventDataItem: EventManagementDataType = {
                                id: eId,
                                title: eTitle,
                                description: eDescription,
                                startDateString: eStartDateString,
                                endDateString: eEndDateString,
                                photoURLs: ePhotoURLs,
                                photoURL: ePhotoURL,
                                viewCount: 0,
                                location: remoteItm.location,
                                address: eAddress,
                                price: ePrice,
                                status: remoteItm.status,
                              }
                              if (eStatus == 'ended'){
                                pEventData.push(eventDataItem)
                              }
                              else{
                                newEventData.push(eventDataItem)
                              }
                              
                          })
                          setPastEventData(pEventData)
                          setEventData(newEventData)
                      }
                  }
                
              }
              else{
                setOrgFound(false)
                setOrgData({
                  name: 'No Organization',
                  description: '',
                  profileURL: '',
                  id: '',
                })
                if (data.error != null && data.error != ""){
                  if (data.error == "token"){
                    //refresh token and try again
                    alert('Please refresh token!')
                  }
                  else {
                    alert(`${data.error}`)
                  }
                }
                else{
                  
                }
                
              }
              
            })

          }).catch((e) => {
            setOrgFound(false)
            alert(e)
          })


      })

    

    }

    useEffect(()=>{
        if (localStorage.getItem('user') != null){
          let userStr = localStorage.getItem('user')
          let userD = JSON.parse(userStr || '{user:{}}')
          setUserData(userD.user)
        }
        loadOrgDetails()
        
      },[])


      const draftEventComponents = () => {
        return (
          <div>
          <div className="mt-5 mb-5">
            <h5>Events waiting for review ({draftEvents.length})</h5>
          </div>
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {draftEvents.map(
        (eventD) => (
          <EventTemplateCard key={eventD.id} data={eventD} />
        )
      )}
</div>
</div>
        )
      }

      const pastEventComponents = () => {
        return (
          <div>
            <div className="mt-5 mb-5">
    <h5>Past Events ({pastEventData.length})</h5>
  </div>
  <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {pastEventData.map(
        (eventD) => (
          <EventManagementCard key={eventD.id} data={eventD} />
        )
      )}
</div>
          </div>
        )
      }

      const liveEventComponents = () => {
        return (
          <div>
            <div className="mt-5 mb-5">
    <h5>Live events ({eventData.length})</h5>
  </div>
  <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {eventData.map(
        (eventD) => (
          <EventManagementCard key={eventD.id} data={eventD} />
        )
      )}
</div>
          </div>
        )
      }

  return (
    <div>
      <CommonLayout>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-semibold">{orgData.name == '' ? 'No Organization' : orgData.name}</h2>
            <ButtonPrimary href="/create-event">Create Event</ButtonPrimary>
          </div>
          <h5>{orgFound ? 'Read EventGo policies before you create event' : 'Create your first event on EventGo'}</h5>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          {orgFound &&

<Tab.Group>
<Tab.List className="flex space-x-1 overflow-x-auto">
  {categories.map((item) => (
    <Tab key={item} as={Fragment}>
      {({ selected }) => (
        <button
          className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
            selected
              ? "bg-secondary-900 text-secondary-50 "
              : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
          } `}
        >
          {item}
        </button>
      )}
    </Tab>
  ))}
</Tab.List>
<Tab.Panels>
  
  <Tab.Panel>
  <div>
    <span className="font-semibold">Event Report</span><br/>
    <span>Total Orders: {orderSummary.totalOrder}</span><br/>
    <span>Total Members: {orderSummary.totalEmails}</span><br/>
    <span>Total Tickets: {orderSummary.totalTickets}</span><br/>
    <span>Total Revenue: {orderSummary.totalRevenue.toLocaleString('en-US',{style: 'currency', currency: 'CAD'})}</span><br/>
  <DataGrid 
    checkboxSelection 
    sx={{ overflowX: 'scroll', marginBottom: 5, marginTop: 5 }}
    rows={orderByEvent} 
    columns={orderByEventColumn} />
  </div>
  {draftEvents.length > 0 && draftEventComponents()}
  {eventData.length > 0 && liveEventComponents() }
  {pastEventData.length > 0 && pastEventComponents() }
  </Tab.Panel>

  <Tab.Panel>
  <div className="space-y-6 sm:space-y-8">
    {/* HEADING */}
    <h2 className="text-3xl font-semibold">All Orders({orderData.length})</h2>
    <DataGrid 
      checkboxSelection 
      sx={{ overflowX: 'scroll', marginBottom: 5, marginTop: 5 }}
      rows={orderData} 
      columns={ordersColumn} />
  </div>

  </Tab.Panel>
  
  <Tab.Panel>
  <div className="space-y-6 sm:space-y-8">
{/* HEADING */}
<h2 className="text-3xl font-semibold">Organization infomation</h2>
<div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
<div className="flex flex-col md:flex-row">
<div className="flex-shrink-0 flex items-start">
  <div className="relative rounded-full overflow-hidden flex">
    <Avatar imgUrl={orgData.profileURL} sizeClass="w-32 h-32" />
    <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
          stroke="currentColor"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <span className="mt-1 text-xs">Change Logo</span>
    </div>
    <input
      type="file"
      className="absolute inset-0 opacity-0 cursor-pointer"
    />
  </div>
</div>
<div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6">
  <div>
    <Label>Organization Name</Label>
    <Input className="mt-1.5" defaultValue={orgData.name} />
  </div>
  {/* ---- */}
  <div>
    <Label>Description</Label>
    <Textarea className="mt-1.5" defaultValue={orgData.description} />
  </div>

  <div className="flex justify-between items-center">
    <Label><b>Organization ID:</b></Label>
    <Label className="mt-1.5">{orgData.id}</Label>
  </div>

  <div className="flex justify-between items-center">
    <Label><b>URL:</b></Label>
    <Label className="mt-1.5">https://evtgo.com/organizer/{orgData.id}</Label>
  </div>

  <div className="pt-2">
    <ButtonPrimary>Update info</ButtonPrimary>
  </div>
</div>
</div>
</div>
  
  </Tab.Panel>

</Tab.Panels>
</Tab.Group>
          
          
          
          }
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountOrganization;
