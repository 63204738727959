import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { auth, fs } from 'firebaseSetup'
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendPasswordResetEmail } from "firebase/auth";
import { getDoc, getDocs, collection, doc, setDoc, Timestamp } from "firebase/firestore"

export interface PageLoginProps {
  className?: string;
}


const PageForgetPass: FC<PageLoginProps> = ({ className = "" }) => {
  const [userInfo, setUserInfo] = useState({
    email: '',
  })
  async function resetPassword(email:string){
    sendPasswordResetEmail(auth,email).then((userVal) => {
        alert("Password reset email sent")
    }).catch((e)=>{
      alert(e.message)
    })
  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>EventGo | Forget Password</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Forget Password
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6">
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                onChange={(e)=>{
                  const emailA = e.target.value
                  let userIn = userInfo
                  userIn.email = emailA
                  setUserInfo(userIn)
                }}
                className="mt-1"
              />
            </label>                    
          </form>
          <ButtonPrimary onClick={()=>{
            resetPassword(userInfo.email)
          }}>Reset Password</ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default PageForgetPass;
